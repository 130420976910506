import React from 'react';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import { FormInputText } from '../shared/FormInputText';
import {
    Button,
    Box,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Grid,
} from '@mui/material';

import { useForm } from 'react-hook-form';

function FormRow(props) {
    const { control, name, label, rules, inputType, readonly } = props;
    return (
        <React.Fragment>
            <Grid item
                md={6}
                xs={10}
                lg={4}
            >
                <FormInputText
                    name={name}
                    control={control}
                    label={label}
                    rules={rules}
                    inputType={inputType || "text"}
                    readonly={readonly || false}
                    helperText={props.helperText || null}
                />
            </Grid>
        </React.Fragment>
    );
}
function FormRowRadio(props) {
    const { control, name, label, options, rules, readonly } = props;
    return (
        <Grid item
            md={6}
            xs={10}
            lg={4}
        >
            <FormInputDropdown name={name}
                control={control}
                label={label}
                options={options}
                readonly={readonly || false}
                rules={rules}
            />
        </Grid>
    );
}

const loan_purpose = [
    { value: "Green Loan", label: "Green Loan" },
    { value: "Sakthi Entrepreneurship Loan", label: "Sakthi Entrepreneurship Loan" },
    { value: "Working Capital Loan", label: "Working Capital Loan" },
    { value: "Live Stock Farming Loan", label: "Live Stock Farming Loan" },
    { value: "Personal loan", label: "Personal loan" },
    { value: "Customer Durable Loan", label: "Customer Durable Loan" },
]

const genderList = [
    {
        label: "Male",
        value: "male"
    },
    {
        label: "Female",
        value: "female"
    },
    {
        label: "Transgender",
        value: "transgender"
    }
]

const businessPremises = [
    {
        label: "Rented",
        value: "rented"
    },
    {
        label: "Owned",
        value: "owned"
    },
    {
        label: "Leased",
        value: "leased"
    }
]

const companyCategory = [
    {
        label: "State Government",
        value: "State Government"
    }, {
        label: "Central Government",
        value: "Central Government"
    },
    {
        label: "Private Limited",
        value: "Private Limited"
    }
]

const onSubmit = (data) => {
    console.log(data, "dataaaaabhbubuib");
}

const LoanType = () => {
    const { handleSubmit, control, watch } = useForm();
    const selectedLoanType = watch("loan_Type")
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardHeader title="Loan Type" />
                <Divider />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                <FormInputDropdown
                                    name="loan_Type"
                                    control={control}
                                    label="Loan Type"
                                    options={loan_purpose}
                                // rules={CustomerRules.guarantor_relationship}
                                />
                            </Box>
                        </Grid>
                        {selectedLoanType === "Sakthi Entrepreneurship Loan" && (
                            <>
                                <Grid container item spacing={2} >
                                    <FormRowRadio
                                        name="type_of_industry"
                                        control={control}
                                        label="Type of Industry"
                                        options={genderList}
                                    />
                                    <FormRowRadio
                                        name="trade_name"
                                        control={control}
                                        label="Trade Name"
                                        options={genderList}
                                    />
                                    <FormRow control={control} name="busines_experience" label="Busines experience" />
                                </Grid>
                                <Grid container item spacing={2} >
                                    <FormRow control={control} name="years_of_operation" label="years of operation" />
                                    <FormRow control={control} name="Location_of_operation" label="Location of operation" />
                                    <FormRowRadio
                                        name="business_premises"
                                        control={control}
                                        label="Business Premises"
                                        options={businessPremises}
                                    />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="total_investment" label="Total Investment" />
                                    <FormRow control={control} name="total_employee" label="Total employee" />
                                    <FormRowRadio
                                        name="women_employee"
                                        control={control}
                                        label="Women employee"
                                        options={businessPremises}
                                    />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="annual_income" label="Annual Income" />
                                    <FormRow control={control} name="pan_card_no" label="Pan card No" />
                                    <FormRow control={control} name="gst_no" label="GST No" />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="required_loan_amt" label="Required Loan Amt" />
                                    <FormRow control={control} name="required_loan_tenure" label="Required Loan Tenure" />
                                    <FormRow control={control} name="phone_no/email" label="Phone No/Email" />
                                </Grid>
                            </>
                        )}
                        {selectedLoanType === "Working Capital Loan" && (
                            <>
                                <Grid container item spacing={2} >
                                    <FormRowRadio
                                        name="type_of_industry"
                                        control={control}
                                        label="Type of Industry"
                                        options={genderList}
                                    />
                                    <FormRowRadio
                                        name="trade_name"
                                        control={control}
                                        label="Trade Name"
                                        options={genderList}
                                    />
                                    <FormRow control={control} name="busines_experience" label="Busines experience" />
                                </Grid>
                                <Grid container item spacing={2} >
                                    <FormRow control={control} name="years_of_operation" label="years of operation" />
                                    <FormRow control={control} name="Location_of_operation" label="Location of operation" />
                                    <FormRowRadio
                                        name="business_premises"
                                        control={control}
                                        label="Business Premises"
                                        options={businessPremises}
                                    />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="total_investment" label="Total Investment" />
                                    <FormRow control={control} name="total_employee" label="Total employee" />
                                    <FormRowRadio
                                        name="women_employee"
                                        control={control}
                                        label="Women employee"
                                        options={businessPremises}
                                    />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="annual_income" label="Annual Income" />
                                    <FormRow control={control} name="pan_card_no" label="Pan card No" />
                                    <FormRow control={control} name="gst_no" label="GST No" />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="required_loan_amt" label="Required Loan Amt" />
                                    <FormRow control={control} name="required_loan_tenure" label="Required Loan Tenure" />
                                    <FormRow control={control} name="phone_no/email" label="Phone No/Email" />
                                </Grid>
                            </>
                        )}
                        {selectedLoanType === "Green Loan" && (
                            <>
                                <Grid container item spacing={2} >
                                    <FormRowRadio
                                        name="purpose_of_loan"
                                        control={control}
                                        label="Purpose of loan"
                                        options={genderList}
                                    />
                                    <FormRow control={control} name="land_size" label="Land Size" />
                                </Grid>
                                <Grid container item spacing={2} >
                                    <FormRow control={control} name="land_value" label="Land Value" />
                                    <FormRow control={control} name="location_of_operation" label="Location of operation" />
                                    <FormRow control={control} name="land_area" label="Land Area" />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="experience" label="Experience" />
                                    <FormRow control={control} name="ownership" label="Ownership" />
                                    <FormRow control={control} name="annual_income" label="Annual Income" />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="loan_amt" label="Loan Amt" />
                                    <FormRow control={control} name="tenure" label="Tenure" />
                                    <FormRow control={control} name="phone_no/email" label="Phone No/Email" />
                                </Grid>
                            </>
                        )}
                        {selectedLoanType === "Live Stock Farming Loan" && (
                            <>
                                <Grid container item spacing={2} >
                                    <FormRowRadio
                                        name="purpose_of_loan"
                                        control={control}
                                        label="Purpose of loan"
                                        options={genderList}
                                    />
                                    <FormRowRadio
                                        name="type_of_livestock"
                                        control={control}
                                        label="Type of Livestock"
                                        options={genderList}
                                    />
                                    <FormRow control={control} name="no_of_livestock" label="No of Livestock" />
                                </Grid>
                                <Grid container item spacing={2} >
                                    <FormRow control={control} name="value" label="Value" />
                                    <FormRow control={control} name="experience" label="Experience" />
                                    <FormRow control={control} name="additional_income" label="Additional Income" />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="annual_income" label="Annual Income" />
                                    <FormRow control={control} name="required_loan_amt" label="Required Loan Amt" />
                                    <FormRow control={control} name="loan_tenure" label="Loan Tenure" />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="phone_no/email" label="Phone No/Email" />
                                </Grid>
                            </>
                        )}
                        {selectedLoanType === "Personal loan" && (
                            <>
                                <Grid container item spacing={2} >
                                    <FormRowRadio
                                        name="purpose_of_loan"
                                        control={control}
                                        label="Purpose of loan"
                                        options={genderList}
                                    />
                                    <FormRowRadio
                                        name="company_category"
                                        control={control}
                                        label="Company Category"
                                        options={companyCategory}
                                    />
                                    <FormRow control={control} name="designation" label="Designation" />
                                </Grid>
                                <Grid container item spacing={2} >
                                    <FormRow control={control} name="employee_id" label="Employee ID" />
                                    <FormRow control={control} name="employee_since" label="Employee Since" />
                                    <FormRow control={control} name="annual_salary" label="Annual Salary" />
                                </Grid>
                                <Grid container item spacing={2}>
                                    <FormRow control={control} name="loan_amt" label="Loan Amt" />
                                    <FormRow control={control} name="loan_tenure" label="Loan Tenure" />
                                    <FormRow control={control} name="office_phone_no/email" label="office Phone No/Email" />
                                </Grid>
                            </>
                        )}
                        {selectedLoanType === "Customer Durable Loan" && (
                            <>
                                <Grid container item spacing={2} >
                                    <FormRow control={control} name="customerId" label="Customer ID" />
                                    <FormRowRadio
                                        name="purpose_of_loan"
                                        control={control}
                                        label="Purpose of loan"
                                        options={companyCategory}
                                    />
                                </Grid>
                                <Grid container item spacing={2} >
                                    <FormRow control={control} name="loan_amount" label="Loan Amount" />
                                    <FormRow control={control} name="loan_tenure" label="Loan Tenure" />
                                    <FormRow control={control} name="phone_no" label="Phone No" />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ mt: 2, ml: 1 }}
                        href="/app/customers"
                        style={{ height: '36px' }}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>
                        {/* {activeStep !== 0 && ( */}
                        <Button sx={{ mt: 2, ml: 1 }}>
                            Previous
                        </Button>
                        {/* )} */}

                        <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                            type="submit"
                        >save
                            {/* {activeStep === steps.length - 1 ? 'Finish' : 'Save & Next'} */}
                        </Button>
                    </Box>
                </Box>
            </Card>
        </form>
    );
};

export default LoanType;
